import React from "react"
import Layout from "../components/layout"

const Blog2 = () => (
    <Layout>
        <div style={{height: `calc(100vh - 20.25rem)`}}>
            
        <span>Page Coming Soon</span>
        </div>
    </Layout>
);

export default Blog2;